import * as React from "react";
import { Link } from "gatsby";
import "../styles/typography.scss"


const pageStyles = {
  "background-color": "#2A2A2A",
  "color": "#F2F2F2",
  "padding": "96px",
  "height": "100vh",
}

const linkStyle = {
  "color": "#F2F2F2"
}

const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <h1 >Page not found</h1>
      <p >
        Sorry 😔, we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link style={linkStyle} to="/">Please visit rickydarke.dev</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
